import { useState } from 'react'
import emailjs from 'emailjs-com'
import location from "./location.png"
import vat from "./vat.png"
import emaaa from "./email.png"
import id from "./fingerprint.png"

function timer() {
  return new Promise(resolve => {
    setTimeout(resolve, 1000);
  });
}
const initialState = {
  name: '',
  email: '',
  message: '',
}
export const Contact = (props) => {
  const [{ name, email, message }, setState] = useState(initialState)

  const handleChange = (e) => {
    const { name, value } = e.target
    setState((prevState) => ({ ...prevState, [name]: value }))
  }
  const clearState = () => setState({ ...initialState })

  const handleSubmit = (e) => {
    e.preventDefault()
    console.log(name, email, message)
    emailjs
      .sendForm(
        'service_vk0yla8', 'template_gxvldxa', e.target, 'user_bjfdktCgzdtC6Db51uXLN'
      )
      .then(
        (result) => {
          console.log(result.text)
          clearState()
        },
        (error) => {
          console.log(error.text)
        }
      )
  }
  return (
    <div>
      <div id='contact'>
        <div className='container'>
          <div className='col-md-8'>
            <div className='row'>
              <div className='section-title'>
                <h2>Get In Touch</h2>
                <p>
                  Please fill out the form below to send us an email and we will
                  get back to you as soon as possible.
                </p>
              </div>
              <form name='sentMessage' validate onSubmit={handleSubmit}>
                <div className='row'>
                  <div className='col-md-6'>
                    <div className='form-group'>
                      <input
                        type='text'
                        id='name'
                        value={name}
                        name='name'
                        className='form-control'
                        placeholder='Name'
                        required
                        onChange={handleChange}
                      />
                      <p className='help-block text-danger'></p>
                    </div>
                  </div>
                  <div className='col-md-6'>
                    <div className='form-group'>
                      <input
                        type='email'
                        id='email'
                        value={email}
                        name='email'
                        className='form-control'
                        placeholder='Email'
                        required
                        onChange={handleChange}
                      />
                      <p className='help-block text-danger'></p>
                    </div>
                  </div>
                </div>
                <div className='form-group'>
                  <textarea
                    name='message'
                    id='message'
                    className='form-control'
                    rows='4'
                    value={message}
                    placeholder='Message'
                    required
                    onChange={handleChange}
                  ></textarea>
                  <p className='help-block text-danger'></p>
                </div>
                <div id='success'></div>
                <button type='submit' className='btn btn-custom btn-lg'>
                  Send Message
                </button>
              </form>
            </div>
         
          </div>
            <div id='info' className='col-md-3'>
              <ul>
                <li id='ully'><img src={id} style={{width:'16px',marginRight:'10px'}}></img>BENDABL IKE</li>
                <li id='ully'><img src={location} style={{width:'16px',marginRight:'10px'}}></img>S.Patsi 62, Athens, GR-118 55 </li>
                <li id='ully'><img src={vat} style={{width:'16px',marginRight:'10px'}}></img>VAT: EL801617177</li>
                <li id='ully'><img src={emaaa} style={{width:'16px',marginRight:'10px'}}></img>info@bendabl.com</li>
              </ul>
            
            </div>
          <div className='col-md-12'>
            <div className='row'>
              
            </div>
          </div>
        </div>
      </div>
      <div id='footer'>
        <div className='container text-center'>
          <p>
            &copy; 2025 bendabl
          </p>
        </div>
      </div>
    </div>
  )
}
