import image7 from "../images/MouldingStation.png"
import image5 from "../images/ControlBox.png"
import {Grid,Card,CardContent,Typography} from "@mui/material"
import Container from "@mui/material/Container"
import CardActions from '@mui/material/CardActions';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';

export const Features = (props) => {

  return (
    <div id='features' style={{padding: "100px 0"}}className='text-center'>
      <div className='container'>
        <div className='col-md-10 col-md-offset-1 section-title'>
          <h3 style={{fontSize:'26px'}}>Looking to accelerate your development iterations or ramp-up your soft
robotics solution manufacturing and deployment?</h3>
        </div>
        <h2>We’ve got you covered.</h2>
        <br/><br/>
        <div className='row'>
                <div id='products' className='col-md-4'>
                 
                <i><img src={image5}></img></i>
                  <h3>Control Box</h3>
                  <p style={{textAlign:'justify'}}>Our high fidelity electro-pneumatic control box is a universal solution in programming and controlling any fluidic soft actuator design. It offers control ranging from low to high flows and actuation speeds, supporting air pressures that will satisfy your wildest requirements. With multiple pneumatic outputs, it can be used as a standalone testing platform or integrated into your soft robotics solution.
"</p>
                </div>
                <div id='products' className='col-md-4'>
                  {' '}
                  <i><img src={image7}></img></i>
                  <h3>Moulding Station</h3>
                  <p style={{textAlign:'justify'}}>We act as a one-stop-shop for molding equipment eliminating the hustle of tracking multiple suppliers. We offer a complete moulding station that includes all the tools you need to get you started immediately on your soft robotics project. The box includes pressure and vacuum pumps with gauges, quick connect tubing, vacuum chamber, mixing containers, digital scale, silicone dispensers, latex gloves, and much more.
</p>
                </div>
              
        </div>
        
      </div>
    
                       
    </div>
  )
}
