import image2 from "../images/1.png"
import image3 from "../images/arm.png"
import image6 from "../images/3.png"


export const Services = (props) => {
  return (
    <div id='services' className='text-center'>
      <div className='container'>
        <div className='section-title'>
          <h2>Flexibility. Compliance. Safety.</h2>
          <h3 style={{fontSize:'26px'}}>
          Here’s where we make a difference:
          </h3>
        </div>
        <div className='row' style={{justifyContent:'center',alignItems:'center',display:'flex'}}>
        
          
                <div  className='col-md-3'id='card4'>
                  {' '}
                  <i><img src={image3}></img></i>
                  <div className='service-desc'>
                    <h3>Medical & Rehabilitation</h3>
                    <p>From active surgical instruments to wearable devices that perfectly match the user’s anatomical profile, our technology is the perfect fit</p>
                    <br/>
</div>
                </div>

                <div  className='col-md-3' id='card4'>
                  {' '}
                  <i><img src={image6}></img></i>
                  <div className='service-desc'>
                    <h3>Processing & Manufacturing</h3>
                    <p>Compliant by nature, our grippers can be customized to your application’s needs, ensuring appropriate handling of delicate objects</p>
                    <br/>
               
 </div>
                </div>
                <div  className='col-md-3' id='card4'>
                  {' '}
                  <i><img src={image2}></img></i>
                  <div className='service-desc'>
                    <h3>Inspection & Maintenance</h3>
                    <p>Our soft, pneumatic-driven robotic crawler is ideal for pipeline inspection and repair and inherently compatible with ATEX</p>
                    <br/>
                 
                  </div>
                </div>
             
        </div>
      </div>
    </div>
  )
}
//compatible with ATEX standards